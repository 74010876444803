/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Box, Typography } from '@material-ui/core'
import BlockContent from '@sanity/block-content-to-react'
import { Link } from '@/atoms/link'
import { getUri } from '@/utils/routing'

const styleComponentDict = {
  normal: 'p',
  body2: 'p',
  'p-l': 'p',
  h1: 'h1',
  'h1-xxl': 'h1',
  h2: 'h2',
  'h2-m': 'h2',
  'h2-xl': 'h2',
  h3: 'h3'

}

const styleVariantDict = {
  normal: 'body1',
  body2: 'body2',
  'p-l': 'h4',
  h1: 'h3',
  'h1-xxl': 'h2',
  h2: 'h4',
  'h2-m': 'h5',
  'h2-xl': 'h3',
  h3: 'h5'
}

const Block = (props: any): JSX.Element | null => {
  const { style = 'body1' } = props.node
  if (props.children) {
    return (
      <Typography
        component={styleComponentDict[style]}
        variant={styleVariantDict[style]}
      >
        {props.children}
      </Typography>
    )
  }    
  return null
}  

const serializers = {
  types: {
    h1: (props: any) => <Typography component="h1" variant="h3" {...props} />,
    h2: (props: any) => <Typography component="h2" variant="h4" {...props} />,
    h3: (props: any) => <Typography component="h3" variant="h5" {...props} />,
    li: (props: any) => <li className="special-list-item">{props.children}</li>,
    span: Block,
    block: Block
  },
  marks: {
    link: (props: any) => {
      return (
        <Link
          target={props.mark.targetBlank ? '_blank' : ''}
          to={props.mark.url}
          underline="always"
        >
          {props.children[0]}
        </Link>
      )
    },
    linkInternal: (props: any) => {
      return (
        <Link
          target={props.mark.targetBlank ? '_blank' : ''}
          to={getUri(
            props.mark.pageReference?.slug.current,
            props.mark.pageReference?._type
          )}
          underline="always"
        >
          {props.children[0]}
        </Link>
      )
    }
  }
}

const RichTextModule: FC<any> = ({ text, textRaw, siteType }) => {
  const margin = siteType === 'store' ? 0 : 'auto'
  return text ? (
    <Box
      px={{ xs: 3, sm: 0 }}
      mb={3}
      mx={{ xs: 'auto', sm: 'auto', md: margin }}
      maxWidth="72rem"
    >
      <BlockContent blocks={text.textRaw} serializers={serializers} />
    </Box>
  ) : (
    <Box
      px={{ xs: 3, sm: 0 }}
      mb={3}
      mx={{ xs: 'auto', sm: 'auto', md: margin }}
      maxWidth="72rem"
    >
      <BlockContent blocks={textRaw} serializers={serializers} />
    </Box>
  )
}

export default RichTextModule

export const query = graphql`
  fragment moduleRichTextData on SanityModuleRichText {
    text {
      textRaw: _rawText(resolveReferences: { maxDepth: 6 })
    }
  }
  fragment richTextExtended on SanityRichTextExtended {
    textRaw: _rawText(resolveReferences: { maxDepth: 6 })
  }
`
